// WhatsAppButton.jsx

import React from 'react';
import {  useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";


const WhatsAppButton = () => {
  const history = useNavigate();

  const handleWhatsAppClick = () => {

    // Replace '123456789' with the actual phone number you want to message
    const phoneNumber = '9704678952';

    // Replace 'Hello!' with the desired pre-filled message
    const message = encodeURIComponent('Hello!');

    // Create the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open the link in a new tab or window
    window.open(whatsappLink, '_blank');
    history('/')
  };

  return (
    <>
    <div><button className='rounded-5 btn btn-light' onClick={()=>history('/')}><IoMdArrowRoundBack className='fs-4' style={{paddingBottom:'4px'}} />Back</button></div> 

   <div className='py-5 my-5'><center>
    <h5>Booking successfully ! My staff is On the Way</h5>
    <h5>"Click" Share your "Location" please</h5>
    <button onClick={handleWhatsAppClick} className='rounded-5 btn btn-outline-success my-4'>
  <i className="fa fa-whatsapp fs-1" aria-hidden="true"></i>
</button>
   
    </center></div> </>
  );
};

export default WhatsAppButton;
