

import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import successSound from '../Assates/short-success-sound-glockenspiel-treasure-video-game-6346 (1).mp3';
import Fail from "../Assates/error-when-entering-the-game-menu-132111.mp3";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import '../Components1/Booking2.css';
import img4 from '../Assates/car-wash-vector-free-29_clipped_rev_1-1.png';
import { IoMdArrowRoundBack } from "react-icons/io";

const firebaseConfig = {
  apiKey: "AIzaSyCY3yCgIGipMyVtI0Q9wGzDBoq3mHtAO5I",
  authDomain: "car-360-washing.firebaseapp.com",
  databaseURL: "https://car-360-washing-default-rtdb.firebaseio.com",
  projectId: "car-360-washing",
  storageBucket: "car-360-washing.appspot.com",
  messagingSenderId: "950691338224",
  appId: "1:950691338224:web:ebfc5e9cf08eaa113aef35",
  measurementId: "G-G4R22J24V2"
};
firebase.initializeApp(firebaseConfig);

const BookingForm = () => {
  const navigate = useNavigate();
  

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [number, setNumber] = useState('');
  const [servicess, setServicess] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [car, setCar] = useState('');

  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const successAudio = new Audio(successSound);
  const fail = new Audio(Fail);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);


 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const user = firebase.auth().currentUser;
      if (!user) {
        // Handle case where user is not authenticated
        return;
      }
      
      if (!number.trim() || number.trim().length !== 10) {
        setPhoneNumberError('Please Check Your Number');
        return;
      } else {
        setPhoneNumberError('');
      }

      if (!service.trim()) {
        setAddressError('Address is required.');
        return;
      } else {
        setAddressError('');
      }
      console.log('Disabling button...');
      setButtonDisabled(true); // Disable the button to prevent multiple submissions

      //const userLocation = await getUserLocation();


      const db = firebase.database();
      const bookingDate = new Date().toISOString();
      await db.ref('bookings').push({
        userId: user.uid,
        name,
        email,
        number,
        service,
        servicess,
        car,
        bookingDate,
      });

      const templateParams = {
        user_name: name,
        user_email: email,
        from_number: number,
        service_name: service,
        servicess_name: servicess,
        car_name: car
      };

      const emailResult = await emailjs.send('service_w3ch35g', 'template_y01puhl', templateParams, 'qbc57SvIGgzNB0F5-');
      console.log(emailResult.text);
      successAudio.play();

    

      swal("Thank you !", "Your booking successfully!", "success").then(() => {
        navigate('/Booking-Details', { state: { bookingInfo: { servicess, car } } });


      });
     

      e.target.reset();
      setName('');
      setEmail('');
      setServicess('');
      setService('');
      setNumber('');
      setCar('')
      console.log('Enabling button...');
      setButtonDisabled(false); // Re-enable the button after successful submission
      // Re-enable the button after successful submission
    } catch (error) {
      console.error("Error:", error);

      fail.play();
      swal("Sorry!", "Your booking failed. Please try again later.", "error");
      console.log('Enabling button due to error...');
      setButtonDisabled(false);  // Re-enable the button in case of failure
    }
  }

 
  return (
    <>
      <div className="container-fluid " style={{ backgroundColor: loading ? 'transparent' : '' }}>

        {loading ? (
          <center><div style={{top:'200px'}}  className='loader my-5'></div></center>
        ) : (

<>
<div><button className='rounded-5 btn btn-light btn-sm ' onClick={()=>navigate('/bookings3')}><IoMdArrowRoundBack className='fs-4'style={{paddingBottom:'3px'}} />Back</button></div><br/> 

          <center><div className=' fs-3'>Online Booking Service</div></center>

          <div className="container bg-success border border-1 text-black" >

            <img src={img4} width={900} height={218} alt='wash' className="image " />
            <header style={{ fontFamily: 'serif' }}  >
              <form autoComplete='off' className='border border-1 border-secondary py-3 ' onSubmit={handleSubmit}>
                <label >
                  Full Name:
                  <input type="text" name='Full Name' placeholder='Enter Full Name' value={name} onChange={(e) => setName(e.target.value)} required />
                </label>
                <label >
                  Email:
                  <input type="email" name='Email' placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
                <label >
                  Phone No:
                  <input value={number} name='Phone No' placeholder='Enter Phone Number' onChange={(e) => setNumber(e.target.value)} required />
                  {phoneNumberError && <p style={{ color: 'red' }} >{phoneNumberError}</p>}
                </label>

                <label >
                  Vehicle Number:
                  <input type="text" name='Vechile Name & Number' value={car} placeholder='Vehicle Number' onChange={(e) => setCar(e.target.value)} required />

                </label>

                <label >
                  Choose:
                  <select
                    value={servicess} name='Choose' onChange={(e) => setServicess(e.target.value)} required>
                    <option></option>
                    <option  >Special Body Washing With Wax & Polishing-PRICE Rs.449</option>

                  </select>
                </label>
                <label >
                Address,LandMark,Street:
                  <textarea
                    type="text" name='Address'
                    cols={0}
                    rows={4}
                    placeholder='H.NO
                          Street  
                          Loaction Near
                          City'
                         
                    value={service}

                    onChange={(e) => setService(e.target.value)}
                  />
                  {addressError && <p style={{ color: 'red' }}>{addressError}</p>}
                </label>

                <center> <div className='me-3'>
                  <button
                    type="submit"
                    style={{ borderRadius: '10px', width: '300px', opacity: buttonDisabled ? 0.3 : 15, cursor: buttonDisabled ? 'not-allowed' : 'pointer' }}
                    disabled={buttonDisabled} // Disable the button if it's already clicked
                  >
                    <div >Book Now</div>
                  </button>

                </div></center>

              </form>
         
          </header> </div>

        </>
       )}
    </div >
    </>
  );
};

export default BookingForm;