import React, { useState, useEffect } from 'react';
import './Form.css'
//import Bookings from '../Components7/Booknow'
import About from '../Components3/About';
import Slider from '../Components2/Metial'
import Logo from '../Components10/Logo';
import instagramIcon from '../Assates/instagram.png'; 
import whatsapp from '../Assates/whatsapp.png'
import { useNavigate } from 'react-router-dom';

const Home = () => {
 const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <> {loading ? (
      <center><div style={{top:'200px'}}  className='loader my-5 '></div></center>
      ) : (

      <div>

<a
      href="https://www.instagram.com/mr_robotic_car_washing_360"
                className="instagram-link"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={instagramIcon} width={50}
                    alt="Instagram"
                    className="instagram-icon"
                    style={{position:'fixed', bottom:'40px',left:'5px'}}
                />
            </a>
            <img src={whatsapp} width={55}  style={{color:'#00ff00',cursor: 'pointer'  ,position:'fixed', bottom:'40px', left:'85%'}} alt='sender'onClick={() => navigate('/Appss')}/>


        <Slider />

        

        <br /> <br /><br/>
        <Logo/><hr/>
        <About />
      </div>

    )}
    </>

  );
};


export default Home