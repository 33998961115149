import React from 'react';
import Topbutton from '../Components15/topbutton';

const Policis = () => {
  return (
    <div  >
      <div className='d-flex flex-wrap'>
        <div className="card mb-3  py-1 my-1 ms-5 " style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}}>
          <div className="card-body">
            <h4 style={{color:'red'}}> WASHING POLICIES</h4>
            <h4 style={{ color: 'red' }}>1.PAYMENT AND PRICING</h4>

            <div> payments Accept upi methods,This is a total online service so as per our policy after delivering the car to you we will take payment as per our company rules only if you like the car service completely then if you don't like our service then we will cansel the payment saying we will do a better wash next time this is our company rules
              All prices for our car wash services are clearly outlined. We accept various payment methods for your convenience. Please make sure to check and confirm the prices before proceeding with the service.pricing details are available in booking page<br /><h6 style={{ fontSize: '13px'}}>చెల్లింపులు upi పద్ధతులను అంగీకరించండి, ఇది మొత్తం ఆన్‌లైన్ సేవ కాబట్టి మీకు కారును డెలివరీ చేసిన తర్వాత మా పాలసీ ప్రకారం మీరు కారు సేవను పూర్తిగా ఇష్టపడితే మాత్రమే మేము మా కంపెనీ నిబంధనల ప్రకారం చెల్లింపు చేస్తాము, ఆపై మీకు మా సేవ నచ్చకపోతే మేము చేస్తాము ఇది మా కంపెనీ నియమాల ప్రకారం తదుపరిసారి మెరుగైన వాష్ చేస్తాం అని చెప్పి చెల్లింపును రద్దు చేస్తుంది
                మా కార్ వాష్ సేవలకు సంబంధించిన అన్ని ధరలు స్పష్టంగా వివరించబడ్డాయి. మేము మీ సౌలభ్యం కోసం వివిధ చెల్లింపు పద్ధతులను అంగీకరిస్తాము. దయచేసి సేవను కొనసాగించే ముందు ధరలను తనిఖీ చేసి, నిర్ధారించారని నిర్ధారించుకోండి. ధరల వివరాలు బుకింగ్ పేజీలో అందుబాటులో ఉన్నాయి</h6></div>


          </div>
        </div>
        <div className="card mb-3 py-1 my-1 ms-5" style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}} >
          <div className="card-body">
            <h4 style={{ color: 'red' }}>2.APPOINTMENT & SCHEDULING </h4>

            <div className=""> To ensure efficient service, we recommend scheduling appointments in advance. Walk-in customers are also
              welcome, but appointments take priority. Please arrive on time for your scheduled appointment.<br /><h6 style={{ fontSize: '13px'}}>సమర్థవంతమైన సేవను నిర్ధారించడానికి,
                అపాయింట్‌మెంట్‌లను ముందుగానే షెడ్యూల్ చేయాలని మేము సిఫార్సు చేస్తున్నాము. వాక్-ఇన్ కస్టమర్‌లు కూడా స్వాగతం పలుకుతారు, అయితే అపాయింట్‌మెంట్‌లకు ప్రాధాన్యత ఉంటుంది. దయచేసి మీ షెడ్యూల్ చేసిన అపాయింట్‌మెంట్ కోసం సమయానికి చేరుకోండి.</h6>
            </div>

          </div>
        </div>

        <div className="card mb-3 py-1 my-1 ms-5 " style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}} >
          <div className="card-body">
            <h4 style={{ color: 'red' }}>3.VEHICLE CONDITION</h4>

            <div>  Customers are responsible for the removal of personal belongings from their vehicles before the car wash
              service. Mr Robotic Car Wash is not liable for any damage or loss of personal items left in the
              vehicle.<br /><h6 style={{ fontSize: '13px'}}>కార్ వాష్ సేవకు ముందు వారి వాహనాల నుండి వ్యక్తిగత వస్తువులను తీసివేయడానికి వినియోగదారులు బాధ్యత వహిస్తారు. మిస్టర్ రోబోటిక్ కార్ వాష్ వాహనంలో మిగిలి ఉన్న వ్యక్తిగత వస్తువులకు ఏదైనా నష్టం లేదా నష్టానికి బాధ్యత వహించదు. </h6>  </div>

          </div>
        </div>

        <div className="card mb-3 my-1 py-1 ms-5"style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}} >
          <div className="card-body">
            <h4 style={{ color: 'red' }}>4.ENIVIRONMENTALLY RESPONSIBILITY</h4>

            <div> We are committed to environmental sustainability. Our cleaning products are eco-friendly, and we take
              measures to minimize water usage. Your support in keeping our environment clean is appreciated.<br /><h6 style={{ fontSize: '13px'}}>మేము పర్యావరణ సుస్థిరతకు కట్టుబడి ఉన్నాము. మా శుభ్రపరిచే ఉత్పత్తులు పర్యావరణ అనుకూలమైనవి మరియు నీటి వినియోగాన్ని తగ్గించడానికి మేము చర్యలు తీసుకుంటాము. మన పరిసరాలను పరిశుభ్రంగా ఉంచడంలో మీ సహకారం అభినందనీయం.</h6>
            </div>

          </div>
        </div>
        <div className="card mb-3  py-2 my-1 ms-5 " style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}} >
          <div className="card-body">
            <h4 style={{ color: 'red' }}>5. SERVICE GUARANTEE</h4>
            <div> We strive for customer satisfaction. If you are not satisfied with any aspect of the car wash service, please
              bring it to our attention immediately, and we will do our best to address and resolve any concerns.<br /><h6 style={{ fontSize: '13px'}}>మేము కస్టమర్ సంతృప్తి కోసం ప్రయత్నిస్తాము. మీరు కార్ వాష్ సేవకు సంబంధించిన ఏదైనా అంశంతో సంతృప్తి చెందకపోతే, దయచేసి వెంటనే మా దృష్టికి తీసుకురాండి మరియు ఏవైనా సమస్యలను పరిష్కరించడానికి మరియు పరిష్కరించడానికి మేము మా వంతు కృషి చేస్తాము.</h6>
            </div>

          </div>
        </div>
        <div className="card mb-3  py-2 my-1 ms-5" style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}} >
          <div className="card-body">
            <h4 style={{ color: 'red' }}>6.CANCELLATION POLICY</h4>
            <div>   If you need to cancel or reschedule your appointment, please notify us at least [your specified time] in
              advance. Late cancellations may be subject to a fee.<br /><h6 style={{ fontSize: '13px'}}>మీరు మీ అపాయింట్‌మెంట్‌ను రద్దు చేయాలనుకుంటే లేదా రీషెడ్యూల్ చేయాలనుకుంటే, దయచేసి కనీసం [మీ పేర్కొన్న సమయం] మాకు ముందుగా తెలియజేయండి. ఆలస్యమైన రద్దులు రుసుముకి లోబడి ఉండవచ్చు.</h6>
            </div>

          </div>
        </div>
        <div className="card mb-3 py-2 my-1 ms-5"style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}}>
          <div className="card-body">
            <h4 style={{ color: 'red' }}>7.SAFETY AND LIABILITY</h4>
            <div>  For safety reasons, customers are required to follow staff instructions during the car wash process.Mr Robotic Car Wash
              is not responsible for any damage to vehicles resulting from pre-existing conditions or
              unreported damages. These policies are in place to ensure a smooth and enjoyable experience for all customers. If you have any
              questions or concerns, feel free to contact us.<h6 style={{ fontSize: '13px'}}>భద్రతా కారణాల దృష్ట్యా, కార్ వాష్ ప్రక్రియ సమయంలో కస్టమర్‌లు సిబ్బంది సూచనలను పాటించాల్సి ఉంటుంది. ముందుగా ఉన్న పరిస్థితులు లేదా నివేదించబడని నష్టాల వల్ల వాహనాలకు ఏదైనా నష్టం వాటిల్లితే Mr Robotic Car Wash బాధ్యత వహించదు. కస్టమర్లందరికీ సున్నితమైన మరియు ఆనందదాయకమైన అనుభవాన్ని అందించడానికి ఈ విధానాలు అమలులో ఉన్నాయి. మీకు ఏవైనా ప్రశ్నలు లేదా ఆందోళనలు ఉంటే, మమ్మల్ని సంప్రదించడానికి సంకోచించకండి.</h6>

            </div>

          </div>
        </div>









      </div>
      <div><Topbutton/></div>
    </div>
  );
};

export default Policis;
