import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import './Former.css';
import Car from '../Assates/Picsart_24-03-22_19-58-31-305.png'
import { RiLogoutBoxLine } from "react-icons/ri";
import { auth } from '../Components4/firebase';
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";

const ResponsiveDrawer = ({ user, onSignIn, onSignOut }) => {

  const [open, setOpen] = React.useState(false);
  const [theme, setTheme] = useState(false);

  const handileclick = () => {
    setTheme(!theme)
  }
  useEffect(() => {
    if (theme === true) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  })

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  return (
    <>

      <div className=' position-relative  border border-1 rounded-2' >

        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
          style={{ marginRight: '40px', paddingLeft: '20px', top: '85px' }}
        >
          <MenuIcon />
        </IconButton>
        
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          style={{ width: '200px' }}
        >

          <center> <div  style={{ }}>
            {auth.currentUser && (
              <button className='hover-item me-5  rounded-5 ' style={{ top: '50px', left: '17px' }} onClick={handleSignOut}><RiLogoutBoxLine className='my-1' /> Sign Out</button>

            )}

            <List className='rounded-5' style={{ width: '300px', top: '20px', paddingLeft: '0px'}} >
              <ListItem className=' hover-item text-dark w-50 rounded-5 ms-5' onClick={handleDrawerClose} style={{ top: '35px' }} component={Link} to="/">
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem className=' w-50 rounded-5  hover-item text-dark ms-5 ' onClick={handleDrawerClose} style={{ top: '70px' }} component={Link} to="/logo">
                <ListItemText primary="Booking" />
              </ListItem>
              <ListItem className='me- w-50 rounded-5  hover-item text-dark ms-5' onClick={handleDrawerClose} style={{ top: '105px' }} component={Link} to="/services">
                <ListItemText primary="Services" />
              </ListItem>
              <ListItem className='m w-50 rounded-5   hover-item text-dark ms-5' onClick={handleDrawerClose} style={{ top: '140px' }} component={Link} to="/About">
                <ListItemText primary="About" />
              </ListItem>
              <ListItem className='m w-50 rounded-5   hover-item text-dark ms-5' onClick={handleDrawerClose} style={{ top: '175px' }} component={Link} to="/Policies">
                <ListItemText primary="Policies" />
              </ListItem>
              <ListItem className='m w-50 rounded-5   hover-item text-dark ms-5' onClick={handleDrawerClose} style={{ top: '215px' }} component={Link} to="/Booking-Details">
                <ListItemText primary="Booking Details" />
              </ListItem>

            </List>

          </div>
            <button className='me-5 btn btn-outline' onClick={handileclick} style={{ top: '75%', borderRadius: '100%' }}>{theme ? <MdOutlineLightMode className=' fs-1' /> : <MdDarkMode className=' fs-1' />}</button>

          </center>
        </Drawer>
        <div style={{ paddingBottom: '30px' }}>
          <center><img src={Car} width={100} style={{ borderRadius: '100%', bottom: '20%' }} alt='washing' /></center>
        </div>
      </div>

    </>
  );
};

export default ResponsiveDrawer;
