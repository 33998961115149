import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paper, Typography } from '@mui/material';

import Image5 from '../Assates/istockphoto-544745222-612x612.png';
import Image3 from '../Assates/1492690894984-2018_lexus_lc_500_036_0b951cc6f245c42cbc8641cea41949fa2356fa49.jpg';
import Image4 from '../Assates/Leisuwash-360-3D-picture-standard-configuration.2.jpg';
import Image2 from '../Assates/ddfe1.png';
import Image6 from '../Assates/dezinfekce-auta-interier.jpg';
import Image7 from '../Assates/ceramic-coating-car.png';


const customerComments = [
  "Robotic Wash!", 
  "Deep Cleaning. ",
  " Latest techknowlogy",
  "Autometic Washing System ",
  "Steam Wash & dry & wet",
];
const Carousel = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Slider {...settings}>
     {customerComments.map((comment, index) => (
        <div key={index}>
          <Paper className='fst-italic' style={{ padding: 0, textAlign: 'center', position: 'relative', color:'white' }}>
            <img src={getImageByIndex(index)}  alt={`Slide ${index + 1}`} style={{ width: '100%', height: '40vh' }} />
            <Typography variant="h6" style={{ position: 'absolute', top: '90%', left: '30%', transform: 'translate(-50%, -50%)'}}>{comment}
            </Typography>
          </Paper>
        </div>
        ))}
    </Slider>
  );
};

// Helper function to get the corresponding image based on the comment index
const getImageByIndex = (index) => {
  const images = [Image2, Image3, Image4, Image5, Image6,Image7];
  return images[index % images.length];
};

export default Carousel;
