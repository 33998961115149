import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paper, Typography, Rating } from '@mui/material';
import image1 from '../Assates/IMG_20240311_155737.jpg';
import image2 from '../Assates/429657796_2617546395091292_5803177467461569474_n.jpg';
import image3 from '../Assates/IMG_20240328_151859.jpg';

const reviews = [
  { name: 'Sai Sudhaker', rating: 5, comment: "I'm blown away by the results of the MR ROBOTIC CAR 360 Wash. The attention to detail is remarkable, and my car shines like never before. Definitely deserving of 5 stars!" , image: image1 },
  { name: 'Ashok Kalyanam', rating: 5, comment: "The MR ROBOTIC CAR 360 Wash is a game-changer! It's quick, convenient, and the results speak for themselves. My car has never looked this good. 5 stars all the way!", image:image2 },
  { name: 'K.Rana Dheer', rating: 5, comment: "Absolutely amazed by the precision and thoroughness of the MR ROBOTIC CAR 360 Wash! My car looks brand new every time I use their service. Definitely a 5-star experience!", image: image3},
];

const ReviewCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
   <div className='card-body ms-5 me-5'><Slider {...settings}>
      {reviews.map((review, index) => (
        <div key={index}>
          <Paper style={{ padding: '20px', textAlign: 'center', maxWidth: '400px', margin: 'auto', backgroundColor: 'black' }}>
           <center><img src={review.image} alt={`Review by ${review.name}`} style={{ width: '25%', height: 'auto', borderRadius: '50%' }} /></center> 
            <Typography variant="h6" style={{ marginTop: '10px',color:'white' }}>{review.name}</Typography>
            <Rating value={review.rating} readOnly />
            <Typography variant="body1" style={{ marginTop: '10px',color:'white' }}>{review.comment}</Typography>
          </Paper>
        </div>
      ))}
    </Slider></div> 
  );
};

export default ReviewCarousel;


