import React from 'react';
import { IoMdArrowRoundBack } from "react-icons/io";
import img1 from '../Assates/Picsart_24-04-16_14-25-26-492.png'
import img2 from '../Assates/Picsart_24-03-27_11-18-40-801.png'
import { useNavigate } from "react-router-dom";
import '../Components7/bookingnow.css'
const Booknow = () => {

  const navigate = useNavigate();

  return (
    <>

      <div><button className='rounded-4 btn btn-light btn-sm  ' onClick={() => navigate('/logo')}><IoMdArrowRoundBack className='fs-4  ' style={{ paddingBottom: '4px' }} />Back</button></div> <br />

     <div className='d-flex flex-wrap '>

      <div className=" ms-5  border border-1  my-2 py-4 d-flex flex-wrap" style={{ width: '18rem' }}>
          <center>   <img src={img1} alt='wash' className=" border border-1 " style={{ width: '90%' }}  onClick={() => navigate("/bookings3")} /> <br /><br />
            <div className="card-body ">
              <h4 className='card-title text-decoration-underline' style={{ color: 'green' }} >ON-LINE SERVICESS</h4>
              <div style={{ textAlign: 'start', paddingLeft: '15px' }}>Here at Total, we pick up quickly and deliver quickly, our organization is fully responsible for the car from the time it is picked up in Ontime to the time it is delivered. You will also get gift packs, the first response will be yours.<br /><br /><h6 style={{ fontSize: '14px' }}>
                ఇక్కడ మొత్తం, మేము త్వరగా పికప్ చేస్తాము మరియు త్వరగా డెలివరీ చేస్తాము, ఆన్‌టైమ్‌లో కారు తీసుకున్నప్పటి నుండి డెలివరీ అయ్యే వరకు మా సంస్థ పూర్తి బాధ్యత వహిస్తుంది. మీరు గిఫ్ట్ ప్యాక్‌లను కూడా పొందుతారు,మొదటి ప్రతిస్పందన మీదే.</h6></div>

              <button
                type="submit"
                className="booking-btn btn-sm rounded-5 text-light me-1"
                onClick={() => navigate("/bookings3")}

              >
                Go To Book Online Service
              </button>
            </div>
          </center>
        </div>

        <center>
          <div className=" ms-5 border border-1 my-2 py-4 d-flex flex-wrap " style={{ width: '18rem' }}>

            <center><img src={img2} alt='wash' className=" border border-1" style={{ width: '90%' }}  onClick={() => navigate("/bookings4")} /><br /><br />
              <div className="card-body mb-1">
                <h4 className='card-title  text-decoration-underline' style={{ color: 'red' }}>OFF-LINE SERVICESS</h4>

                <div style={{ textAlign: 'start', paddingLeft: '15px' }}>If you book the service offline you will have to come yourself, your vehicle will be given first priority and if you book, you will also get discounts and gifts.If you want to use this service for sure, Mr. Robotic Cars 360 Washing<br /><br /><h6 style={{ fontSize: '14px' }}>
                  మీరు సర్వీస్‌ను ఆఫ్‌లైన్‌లో బుక్ చేసుకొని వస్తే మీరే రావాల్సి ఉంటుంది ,ఆలా వస్తే  మీ వాహనానికి మొదటి ప్రాధాన్యత ఇవ్వబడుతుంది మరియు మీరు బుకింగ్ చేస్తే, మీకు డిస్కౌంట్లు మరియు బహుమతులు కూడా లభిస్తాయి.ఈసేవను కచ్చితంగా వాడుకోవాలని కోరుతూ మీ మిస్టర్ రోబోటిక్ కార్స్ 360 వాషింగ్ </h6></div>
                <button
                  type="submit"
                  className="booking-btns text-light rounded-5 me-1"
                  onClick={() => navigate("/bookings4")}
                >
                  Go To Book Offline Service
                </button>
              </div></center>

          </div></center>
         
      </div>




    </>
  )
}
export default Booknow