import React from "react"
import Ian from '../Assates/image1-10.jpg'
import Iam from '../Assates/car-upholstery-cleaning.jpg'
import Ias from '../Assates/bestcarseat-cleaner.jpg'
import Ips from '../Assates/car-ceramic-coating-paint-protection-near-me.jpg'
import './Galary.css'


const Galary = () => {

    return(
        <>
<div className="container booking-wrapper  ">
<div className="booking-item me-2"  >

  <img src={Ian} alt="Under Body & Body" className="booking-image" />

</div>
<div className="booking-item me-2" >
  <img src={Iam} alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={Ias} alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={Ips} alt="Under Body & Body" className="booking-image" />
</div>

</div>
</>
    )
}
export default Galary