import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { useNavigate } from 'react-router-dom'
import whatsapp from '../Assates/whatsapp.png'

const BookingDetails = () => {
  const navigate = useNavigate(); 
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (!user) {
      // Handle case where user is not authenticated
      return;
    }
  
    const fetchUserBookings = () => {
      const db = firebase.database();
      const bookingsRef = db.ref('bookings');
      bookingsRef.orderByChild('userId').equalTo(user.uid).on('value', (snapshot) => {
        const bookingsData = snapshot.val();
        if (bookingsData) {
          const userBookingsArray = Object.values(bookingsData);
          setBookings(userBookingsArray);
        } else {
          setBookings([]);
        }
      });
    };
  
    fetchUserBookings();
  
    return () => {
      const db = firebase.database();
      const bookingsRef = db.ref('bookings');
      bookingsRef.off();
    };
  }, []);
  
  return (
    <>

    <center> 
       <div className='card-body bg-warning border border-1 ms-2 rounded rounded-5 py-3 my-2 me-2'>

     <h2 style={{textDecorationLine: 'underline',paddingLeft:'20px',color:'red'}}>Booking Details</h2>

      {bookings.length > 0 ? (
        <ul style={{listStyleType:'none'}}>
          {bookings.map((booking, index) => (
             <li key={index}>
          
             <strong>Thanks For Your Booking<br/> </strong> MR.{booking.name}<br />
              <strong>Select Service:<br/></strong> {booking.servicess}<br />
              <strong>Vehicle Number:<br/></strong> {booking.car}<br />
              <strong>Booking Date:<br/></strong> {new Date(booking.bookingDate).toLocaleString()}<hr className='me-4'/>

            </li>
          ))}
        </ul>
      ) : (
        <p>No bookings found.</p>
      )}
     <h4> "Click" Share Your location </h4>
            <img src={whatsapp} width={45}  style={{color:'#00ff00',cursor: 'pointer' }} alt='sender'onClick={() => navigate('/Appss')}/>

</div>
</center>

    </>
  );
};

export default BookingDetails;
