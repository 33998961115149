import React from "react";
import img1 from '../Assates/IMG_20240310_190158.jpg'
import img2 from '../Assates/IMG_20240310_190217.jpg'
import img3 from '../Assates/IMG_20240310_190119.jpg'
import img4 from '../Assates/Picsart_24-03-21_22-49-46-019.png'
import img5 from '../Assates/Picsart_24-03-21_22-56-22-358.png'
import img6 from '../Assates/IMG_20240310_190217.jpg'

import { useNavigate } from "react-router-dom";
import './Logo.css';

const Logo = () => {
  const navigate = useNavigate();
  return (
    <>

      <div className="card-body my-2">

        <center>
<div>
            <strong className=" text-decoration-underline fs-5">BOOK SERVICE ON-LINE & OFF-LINE</strong>
            </div>
        </center> <br />
        <center>
          <div className=" col-sm-6 logo-item "  >
            <img src={img1} alt="logo" className="logo-img  border border-1 border-black shadow" onClick={() => navigate("/bookNow")} style={{ width: '30%', borderRadius: '100%' }}></img>
            <h6 className="card-text">Car washing</h6>
          </div>
         
          <div className="col-sm-6 logo-item">
            <img src={img2} alt="bike" className="logo-img border border-1 border-black shadow" onClick={() => navigate("/bookNow2")} style={{ width: '30%', borderRadius: '100%' }}></img>
            <h6>Bike washing</h6>
            <hr width={250} ></hr>
          </div>
        </center>
      </div>
   
      <center><div><strong className=" text-decoration-underline fs-5">POLISHING & CERAMIC & TEFLON DETAILING</strong></div></center> <br />
     
      <center>
        <div className="col-sm-6 logo-item" >
          <img src={img3} alt="car " className="logo-img border border-1 border-black shadow" onClick={() => navigate("/ceramic")} style={{ width: '30%', borderRadius: '100%' }}></img>
          <h6>Ceramic & Teflon</h6></div></center><br />
      <center>
        <div className="col-sm-6 logo-item" >
          <img src={img6} alt="car " className="logo-img border border-1 border-black shadow" onClick={() => navigate("/ceramic")} style={{ width: '30%', borderRadius: '100%' }}></img>
          <h6>Ceramic & Teflon </h6><hr width={250} ></hr></div></center>

      <center>
      <div className='text-decoration-underline' >

          <strong className="fs-5">PRICE LIST FOR  ON-LINE BOOKINGS</strong>
        </div>
      </center>

      <div className="card-body ms-2 me-2 my-4 " >
        <table >
          <thead >
            <tr style={{ backgroundColor: 'black' }}  >
              <th className='text-decoration-underline fs-0 text-light' >ON-LINE PICKUP & DELIVERY SERVICESS</th>
              <th className='text-decoration-underline text-light'  >PRICE </th>
            </tr>
          </thead>
          <tbody>
            <tr  >
              <td>Car Full Body And Lower Body Washing</td>
              <td style={{ color: 'blue' }}>Rs.249/-</td>
            </tr>
            <tr>

              <td>Car Lower Body & Full Body,Interior Washing</td>
              <td style={{ color: 'blue' }}>Rs.449/-</td>
            </tr>
            <tr  >

              <td>Car Special Interior & Full Body Washing</td>
              <td style={{ color: 'blue' }}>Rs.999/-</td>
            </tr>
            <tr  >


              <td>Car Special Body Washing With Wax & Polishing</td>
              <td style={{ color: 'blue' }}>Rs.1499/-</td>
            </tr>
            <tr  >

              <td>Two wheeler Special Deep water Washing</td>
              <td style={{ color: 'blue' }}>Rs.99/-</td>
            </tr>
            <tr  >

              <td>Two Wheeler Deep Cleaning Special Foam Washing & Steam</td>
              <td style={{ color: 'blue' }}>Rs.199/-</td>
            </tr>
            <tr  >

              <td>Two wheeler Special Body Washing With Wax & Polishing</td>
              <td style={{ color: 'blue' }}>Rs.449/-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <center>
        <div className='text-decoration-underline' >
          <strong className="fs-5"> PRICE LIST FOR OFF-LINE BOOKINGS</strong>
        </div></center>

      <div className="card-body ms-2 my-4 me-2" >
        <table >
          <thead >
            <tr style={{ backgroundColor: 'black' }} >
              <th className='text-decoration-underline text-light' >OFF-LINE SERVICE TYPES</th>
              <th className='text-decoration-underline text-light' >PRICE </th>
            </tr>
          </thead >
          <tbody >
            <tr  >
              <td >Car Full Body And Lower Body Washing</td>
              <td style={{ color: 'blue' }}>Rs.249/-</td>
            </tr>
            <tr>
              <td>Car Lower Body & Full Body With Interior Washing</td>
              <td style={{ color: 'blue' }}>Rs.449/-</td>
            </tr>
            <tr >
              <td>Car Deep Cleaning Interior & Full Body Washing</td>
              <td style={{ color: 'blue' }}>Rs.999/-</td>
            </tr>
            <tr>
              <td>Car Special Car Body Washing With Wax & Polishing </td>
              <td style={{ color: 'blue' }}>Rs.1499/-</td>
            </tr>
            <tr >
              <td>Two wheeler Special Deep water Washing</td>
              <td style={{ color: 'blue' }}>Rs.99/-</td>
            </tr>
            <tr  >

              <td>Two Wheeler Deep Cleaning Special Foam Washing & Steam</td>
              <td style={{ color: 'blue' }}>Rs.199/-</td>
            </tr>
            <tr  >

              <td>Two wheeler Special Body Washing With Wax & Polishing</td>
              <td style={{ color: 'blue' }}>Rs.449/-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <center> <div >
        <img src={img4} width={200} className='rounded-5' alt="discount" /> <img src={img5} className='rounded-5' width={150} alt="discount" />

      </div></center>
    </>
  )
}
export default Logo;