import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import'../node_modules/bootstrap/dist/css/bootstrap.min.css'
import'../node_modules/font-awesome/css/font-awesome.min.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';



const container = document.getElementById('root');
const root = createRoot(container);
root.render(
 <React.StrictMode>
  <BrowserRouter>
 <App />
 </BrowserRouter>
</React.StrictMode>

 
  
  
);
  
reportWebVitals();
