import React from "react";
import cera from '../Assates/0893400050.jpg'
import teflon from '../Assates/wurth-wax-n-shine-car-polish-500x500.webp'
import Ian from '../Assates/DSC0058.webp'
import Iam from '../Assates/car-service-worker-applying-nano-coating-car-detail_179755-9890.avif'
import Ias from '../Assates/car-detailing-concept-man-face-mask-with-orbital-polisher-repair-shop-polishing-orange-suv-car_627829-12598.avif'
import Ips from '../Assates/professional-auto-ceramic-detailing-666a.jpg'
import passion from '../Assates/123717151_839747963496290_5432155067674225450_n.png'
import pro from '../Assates/124415355_839747996829620_5255018462399293153_n.png'
import r15 from '../Assates/125220419_844095499728203_6602737118784561700_n.png'
import mt from '../Assates/157638625_916931902444562_7065162908287412746_n.png'
import bullet from '../Assates/219787481_999365840867834_508925253837355376_n.png'
import v4 from '../Assates/242824103_1042641499873601_247243906814522233_n.png'
import pul220 from '../Assates/255175052_1079131699557914_7401362166171818210_n.png'
import ertiga from '../Assates/262584547_1086146678856416_4723899367300888681_n.png'

import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import instagramIcon from '../Assates/instagram.png'; 
import guarentee from '../Assates/Best quality - White.png'
import whatsapp from '../Assates/whatsapp.png'

const Ceramic = () => {
const navigate = useNavigate();
    return(
       <>
  <a
      href="https://www.instagram.com/your_instagram_username"
                className="instagram-link"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={instagramIcon} width={50}
                    alt="Instagram"
                    className="instagram-icon"
                    style={{position:'fixed', bottom:'40px'}}
                />
            </a>
            <img src={whatsapp} width={55}  style={{color:'#00ff00',cursor: 'pointer'  ,position:'fixed', bottom:'40px', left:'85%'}} alt='sender'onClick={() => navigate('/Appss')}/>

                 <div><button className=' rounded-4 btn btn-light btn-sm  ' onClick={()=>navigate('/logo')}><IoMdArrowRoundBack className='fs-4  ' style={{paddingBottom:'4px'}}/>Back</button></div> <br/>

       <div className="card-body border border-1 ms-4 me-4 my-2">
       <center><img src={cera}  alt='coating'style={{width:'100px'}} className="my-2"/>
       <h5>Best Ceramic Coating </h5>
       <h6>Remove Scratchess </h6>
        <h6>
          Get Best Price In The Market
       </h6>  <h6> Contact - 9704678952</h6>
       <h6> caontact - whatsapp</h6>
       
       
        </center><br/>
        <div className=' ms-3'style={{textAlign:'start'}} ><h4 className='card-title 'style={{color:'red',fontSize:'18px'}}>WHAT IS CERAMIC COATING ?USE</h4>
        <h5 className="card-text fs-6" >
Ceramic coating for cars is a liquid polymer applied to the exterior surfaces of vehicles. Once cured, it forms a protective layer that bonds with the factory paint, 
acting as a barrier against UV rays, chemicals, bird droppings, and minor scratches. It enhances gloss and provides hydrophobic properties,
 repelling water and making cleaning easier. Ceramic coatings can last years with proper maintenance, offering long-term protection and maintaining the car's appearance. 
 They're popular for their durability and ability to preserve a car's finish</h5>
 <center><img src={guarentee}  alt='coating'style={{width:'100px'}} className="my-2"/></center>

        </div>
       </div>

       <div className="card-body border border-1 ms-4 me-4 ">
        <center><img src={teflon} style={{width:'100px'}} alt='coating' className="my-2"/>
        
        <h5>Best Teflon Coating </h5>
       <h6>Remove Scratchess 90%</h6>
        <h6>
          Get Best Price In The Market
       </h6>  <h6> Contact - 9704678952</h6>
       <h6> caontact - whatsapp</h6>
        </center><br/>
        <div className=' ms-3' style={{textAlign:'start'}}><h4 className='card-title' style={{ color:'red',fontSize:'18px'}}>WHAT IS TEFLON COATING ? USE</h4>
    
       
        <h5 className='card-text fs-6'>

        Teflon coating for cars is a protective layer applied to the exterior surfaces to repel water, oil, and dirt. It creates a hydrophobic barrier,
         making it easier to clean and maintain the vehicle's appearance. Teflon coating can also provide some resistance against minor scratches and UV damage, 
         prolonging the lifespan of the paint. However,
        its durability may be lower compared to ceramic coatings, requiring more frequent reapplications for sustained protection.</h5>
        <center><img src={guarentee}  alt='coating'style={{width:'100px'}} className="my-2"/></center>
       
        </div>
       </div>
       <div className="container booking-wrapper  ">
<div className="booking-item me-2"  >

  <img src={Ian}  alt="Under Body & Body" className="booking-image" />

</div>
<div className="booking-item me-2" >
  <img src={Iam} alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={Ias} alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={Ips} alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={ertiga}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={passion}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={pro}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={r15}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={mt}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={bullet}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={v4}  alt="Under Body & Body" className="booking-image" />
</div>
<div className="booking-item me-2" >
  <img src={pul220}  alt="Under Body & Body" className="booking-image" />
</div>
</div>
       </>
    )
}
export default Ceramic