import img1 from '../Assates/Leisuwash-win5-high-pressure-wash.jpg'
import img2 from '../Assates/7_dsc_6480_0.jpg'
import img3 from '../Assates/ba9f45cb8fb523572d63719c3c87839f.jpg'
import img4 from '../Assates/maxresdefault.jpg'
import img5 from '../Assates/pexels-rdne-stock-project-7363094.jpg'
import './CarWashServices.css'
import Galary from './Galary';
import img6 from '../Assates/on-demand-mobile-car-wash-app-4-638.webp'
import { useNavigate } from 'react-router-dom'
import { IoMdContact } from "react-icons/io";
import { FaSquarePhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { BiSolidTimeFive } from "react-icons/bi";
import { SlCalender } from "react-icons/sl";
import img7 from '../Assates/Best quality - White.png'
import img8 from '../Assates/Safety&Control - White.png'
import img9 from '../Assates/Exteriro_Interior claning - White.png'
import img10 from '../Assates/Picsart_24-03-21_22-49-46-019.png'
import whatsapp from '../Assates/whatsapp.png'
import th from '../Assates/Simple-Minimalist-Background-Image.png'
import Topbutton from '../Components15/topbutton';

import instagramIcon from '../Assates/instagram.png';

const CarWashServices = () => {

  const navigate = useNavigate();



  return (
    <>
      <a
        href="https://www.instagram.com/your_instagram_username"
        className="instagram-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={instagramIcon} width={45}
          alt="Instagram"
          className="instagram-icon"
          style={{ position: 'fixed', bottom: '40px' }}
        />
      </a>
      <img src={whatsapp} width={45}  style={{color:'#00ff00',cursor: 'pointer'  ,position:'fixed', bottom:'40px', top:'78%'}} alt='sender'onClick={() => navigate('/Appss')}/>

      <div >

        <div className='d-flex flex-wrap'>
        <div className="card mb-3 ms-5 my-1  d-flex flex-wrap" style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor: 'black',color:'#00ffff '}}>
            <div className="card-body">
              <h5 className="card-title" style={{  color: 'red' }}>OUR SERVICESS</h5>
              <p className="card-text">➡️ Robotic Foam Washing</p>
              <p className="card-text">➡️ Intearior Deep Washing</p>
              <p className="card-text">➡️ Intearior shanitization</p>
              <p className="card-text">➡️ Body Wax & Polishing </p>

              <p className="card-text">➡️ Ceramic Coating</p>
              <p className="card-text">➡️ Teflon Coating</p>
              <p className="card-text">➡️ Two wheeler Washing</p>
              <p className="card-text">➡️ Steam Washing</p>
              <p className="card-text">➡️ DRY & Wet Vaccumming</p>
              <p className="card-text">➡️ Using Best Company Liquids</p>

              <p className="card-text">➡️ Value For Mony Service</p>
              <center> <img src={img7} width={80} alt='head' /></center>



            </div>
          </div>
          <div className='card   mb-3 ms-5 my-1  d-flex flex-wrap'style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black'}}> 
            <div className="card-body ">
              <h5 className="card-title" style={{ color: 'red' }}>OUR PACKAGES</h5>
              <p className="card-text" style={{color:'#00ffff'}}>CAR WASHING</p>
              <div className='card ' style={{ color:'#00ffff ', backgroundColor:'black',border: '3px solid '}}>
                <p className="card-text">➡️ 6 Months Package Just</p>
                <p className="text-decoration-line-through ms-5 text-warning"> RS.3500 </p>
                <p className="card-text ms-5">RS.2400 /-<br/> Provided-7 Washings</p>
                <p className="card-text ms-5 ">Save Rs.1100 </p>
              </div>
              <div className='card my-2  ' style={{color:'#00ffff ' ,backgroundColor:'black',border: '3px solid ' }}>
                <p className="card-text">➡️ 1 Year Package Just</p>
                <p className="text-decoration-line-through ms-5 text-warning"> RS.7000 </p>

                <p className="card-text ms-5">RS.4800 /-<br/> Provided-14 Washings</p>
                <p className="card-text ms-5 ">Save Rs.2200 </p>
              </div>
              <p className="card-text"  style={{color:'#00ffff '}}>➡️ Value For Mony Service</p>

              <p className="card-text text-warning" >CONTACT-9704678952</p>

              <center> <img src={img9} width={80} alt='head' /></center>


            </div>

          </div>

          <div className="card  mb-3 ms-5 my-1  d-flex flex-wrap"style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}}>
            <div className="card-body ">
              <h5 className="card-title" style={{  color: 'red' }}>OUR DETAILING SERVICE</h5>
              <p className="card-text" style={{ color: 'red'  }}>CERAMIC, TEFLON, POLISHING </p>
              <p className="card-text">➡️ We Use 100% Genune Company Products  </p>
              <p className="card-text">➡️ Well Educated Team Provides a Service</p>
              <p className="card-text">➡️ Remove Scrathess And Protect Your Car Color</p>
              <p className="card-text">➡️ Best Pricess For Ever </p>
              <p className="card-text">➡️ Value For Mony Service</p>

              <p className="card-text  ">CONTACT-9704678952 More Details</p>


              <center> <img src={img7} width={80} alt='head' /></center>


            </div>

          </div>

          <div className="card mb-3 ms-5 my-1   d-flex flex-wrap"style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}}>
            <div className="card-body ">
              <h5 className="card-title" style={{ color: 'red' }}>ONLINE & OFFLINE SERVICE</h5>
              <p className="card-text">Mr.Robotic Company  </p>
              <p className="card-text">➡️ Provided a Online & Offline Servicess  </p>
              <p className="card-text">➡️ Online Servicess Response was Very Quickly</p>
              <p className="card-text">➡️ Offline Service Response was first Priority</p>
              <p className="card-text">➡️ Best Pricess In the Market</p>

              <p className="card-text text-black ">➡️ Professinal Drivers</p>

              <center> <img src={img8} width={80} alt='head' /></center>



            </div>

          </div>

          <div className="card mb-3 ms-5 my-1  d-flex flex-wrap" style={{width: '18rem',border: '3px solid #00ffff ',backgroundColor:'black',color:'#00ffff '}}>
            <div className="card-body ">
              <h5 className="card-title" style={{  color: 'red' }}>OUR OFFERS</h5>
              <p className="card-text">➡️ Every 5 Washings Get 1 Free Washing </p>
              <p className="card-text">➡️ Every Online & Offline bookings get 10% Discount</p>
              <p className="card-text">➡️ Daily Custmer Lucky Draw Win Gifts </p>

              <center><img src={img10} width={150} className='rounded-5' alt="discount" /></center>




            </div>

          </div>
        </div>


        <div  className="container ">

            <img src={img6}  width={328} alt='wash' className="image "  /><br/>
          <div className="text-container">

            <h4 className=' ms-2'  style={{fontFamily: 'Roboto, Arial, Helvetica Neue', color: '#ff0000' }}>Benefits of Online & Offline Service</h4>
            <div  style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', fontSize: '15px', textAlign: 'start', paddingLeft: '15px',  fontWeight: '300' }}>Save time, you can get your vehicle washed anywhere you are by offline booking,
              you can also win prizes, win free washing,
              we try to offer many more offers, we take payment only if you like our service in every way.<h6  style={{ fontSize: '13px' }}>ఇది రోబోటిక్ వాషింగ్ కావున ,సమయం ఆదా అవుతుంది ,
                ఉచిత పికప్ మరియు ఉచిత డెలివరీ సేవలు ,
                మా సర్వీస్ చాల వేగంగా ఉంటుంది ,మీరు కచ్చితంగా మా సర్వీస్ పట్ల హ్యాపీ గ వుంటారు ,ఇది మోతంగా ఫ్రీ,
                చార్జెస్ మాత్రం మీరు అనుకున్న విదంగానే ఉంటాయి</h6></div>

          </div>
        
        </div>

        <div className="container my-0 py-0  ">
          <img src={img1} width={328} alt='wash' className="image  py-2" />
          <div className="text-container ">

            <h4  style={{ fontFamily: 'Roboto, Arial, Helvetica Neue',  color: '#ff0000', textAlign: 'start', paddingLeft: '15px'}}>Lower body and full body</h4>
            <div  style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', fontSize: '15px', textAlign: 'start', paddingLeft: '15px',fontWeight:'300' }}>Our exterior car wash services are designed to remove dirt, grime, and contaminants, leaving your car's exterior sparkling clean.
              We use safe and effective cleaning solutions to protect your vehicle's paint and finish.we are use 100% original products for your car color safe<br /><h6  style={{ fontSize: '13px' }}>మా బాహ్య కార్ వాష్ సేవలు ధూళి, ధూళి మరియు కలుషితాలను తొలగించడానికి రూపొందించబడ్డాయి, మీ కారు వెలుపల మెరిసేలా శుభ్రంగా ఉంటాయి.
                మేము మీ వాహనం యొక్క పెయింట్ మరియు ముగింపును రక్షించడానికి సురక్షితమైన మరియు ప్రభావవంతమైన శుభ్రపరిచే పరిష్కారాలను ఉపయోగిస్తాము. మేము మీ కారు రంగు సురక్షిత కోసం 100% అసలైన ఉత్పత్తులను ఉపయోగిస్తున్నాము</h6>
            </div><hr />

          </div>

        </div>

        <div  className="container py-0 my-0 ">
          <img src={img2} width={328} alt='wash' className="image py-2 " />
          <div className="text-container ">
            <h4 style={{ fontFamily: 'Roboto, Arial, Helvetica Neue',  color: '#ff0000', textAlign: 'start', paddingLeft: '15px'}}>Special liquides using for intearior</h4>

            <div  style={{  fontFamily: 'Roboto, Arial, Helvetica Neue',  fontSize: '15px', textAlign: 'start', paddingLeft: '15px',fontWeight:'300'  }}>Treat your car to a thorough interior cleaning.
              Our team pays attention to every detail, from vacuuming and dusting to cleaning and conditioning surfaces.
              We aim to provide a fresh and comfortable interior for your driving experience.we are using world className intearior cleaning products for your car intearior looking good.<br /><h6 style={{ fontSize: '13px' }}>మీ కారును పూర్తిగా ఇంటీరియర్ క్లీనింగ్‌తో ట్రీట్ చేయండి.
                మా బృందం వాక్యూమింగ్ మరియు డస్టింగ్ నుండి ఉపరితలాలను శుభ్రపరచడం మరియు కండిషనింగ్ చేయడం వరకు ప్రతి వివరాలపై శ్రద్ధ చూపుతుంది.
                మేము మీ డ్రైవింగ్ అనుభవం కోసం తాజా మరియు సౌకర్యవంతమైన ఇంటీరియర్‌ను అందించాలని లక్ష్యంగా పెట్టుకున్నాము. మేము మీ కారు ఇంటీరియర్ అందంగా కనిపించడం కోసం ప్రపంచ స్థాయి ఇంటీరియర్ క్లీనింగ్ ఉత్పత్తులను ఉపయోగిస్తున్నాము.</h6>
            </div><hr />

          </div>
        </div>

        <div  className="container py-0 my-0 ">
          <img src={img3} width={328} alt='wash' className="image py-2" />
          <div className="text-container ">
            <h4  style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', color: '#ff0000', textAlign: 'start', paddingLeft: '15px' }}>Apply ceramic coting for car color safe</h4>

            <div   style={{fontFamily: 'Roboto, Arial, Helvetica Neue',  fontSize: '15px', textAlign: 'start', paddingLeft: '15px',fontWeight:'300'  }}>
              we are provide Apply Ceramic .We using Ceramic Product was genune Product .Enhance the shine and protection of your car's paint with our polishing and waxing services.
              We use quality products to restore and protect the exterior, leaving a glossy and protective finish.we are using a good quality ceramic products <br /><h6   style={{ fontSize: '13px' }}>మేము సిరామిక్‌ని వర్తింపజేస్తాము.మేము సిరామిక్ ఉత్పత్తిని ఉపయోగిస్తాము నిజమైన ఉత్పత్తి .మా పాలిషింగ్ మరియు వాక్సింగ్ సేవలతో మీ కారు పెయింట్ యొక్క షైన్ మరియు రక్షణను మెరుగుపరచండి.
                బాహ్య భాగాన్ని పునరుద్ధరించడానికి మరియు రక్షించడానికి మేము నాణ్యమైన ఉత్పత్తులను ఉపయోగిస్తాము, నిగనిగలాడే మరియు రక్షణాత్మక ముగింపును వదిలివేస్తాము. మేము మంచి నాణ్యత గల సిరామిక్ ఉత్పత్తులను ఉపయోగిస్తున్నాము</h6>
            </div><hr />

          </div>
        </div>
        <div  className="container py-0 my-0 ">
          <img src={img4} width={328} alt='wash' className="image py-2 " />
          <div className="text-container ">
            <h4   style={{fontFamily: 'Roboto, Arial, Helvetica Neue', color: '#ff0000', textAlign: 'start', paddingLeft: '15px'}}>For Two Wheeler Special</h4>

            <div   style={{ fontFamily: 'Roboto, Arial, Helvetica Neue',fontSize: '15px', textAlign: 'start', paddingLeft: '15px',fontWeight:'300' }}>We are provide a Special Servissing on your bike .come Today Change your bike looks,we using a good quality liquides for your bike color<br />
              <h6  style={{ fontSize: '13px' }}>మేము మీ బైక్‌పై ప్రత్యేక సేవలను అందిస్తున్నాము. ఈరోజు రండి మీ బైక్ రూపాన్ని మార్చుకోండి, మేము మీ బైక్ రంగు కోసం మంచి నాణ్యమైన లిక్విడ్‌లను ఉపయోగిస్తాము
              </h6> </div><hr />
          </div>
        </div>

        <div className="container   py-0 my-0 ">
          <img src={img5} width={328} alt='wash' className="image py-2 " />

          <div  className="text-container ">
            <h4 style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', color: '#ff0000', textAlign: 'start', paddingLeft: '15px'}}>www.roboticcarwash.com</h4>
            <div style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', fontSize: '15px', textAlign: 'start', paddingLeft: '15px',fontWeight:'300' }}>This website is completely free for you to book washing service.
              If you book the service, our staff will come to your place and pick up your vehicle and deliver it to you.
              If you like our service in every way, then pay the amount.We have experienced drivers who provide this service.
              Your vehicle will be picked up within 10 minutes of booking the service.
              Our service is very speedy service. and we are introduce offline servicess also.
              <br /><h6  style={{ fontSize: '13px'}}>ఈ వెబ్‌సైట్ మీకు వాషింగ్ సేవను బుక్ చేసుకోవడానికి పూర్తిగా ఉచితం.
                మీరు సేవను బుక్ చేసుకుంటే,
                మా సిబ్బంది మీ స్థలానికి వచ్చి మీ వాహనాన్ని తీసుకొని మీకు డెలివరీ చేస్తారు.
                మీరు మా సేవను అన్ని విధాలుగా ఇష్టపడితే,
                ఆ మొత్తాన్ని చెల్లించండి. ఈ సేవను అందించే అనుభవజ్ఞులైన డ్రైవర్లు మా వద్ద ఉన్నారు.
                సేవను బుక్ చేసిన 10 నిమిషాలలోపు మీ వాహనం పికప్ చేయబడుతుంది. మా సేవ చాలా వేగవంతమైన సేవ.
                మరియు మేము ఆఫ్‌లైన్ సేవలను కూడా పరిచయం చేస్తున్నాము</h6></div>

          </div>

        </div>
        <Galary />
        <div className='container d-flex flex-wrap my-0 py-0 text-light position-relative' style={{ backgroundImage: `url(${th})`, backgroundSize: 'cover', backgroundPosition: 'center', height:'auto'}}>

       

          <div className="container py-3 " >
            <div className="text-container text-light">
              <h4  style={{  color: '#ff0000', textAlign: 'start', paddingLeft: '15px' }}>Why Choose MrRobotic car Washing ?</h4>
              <p   style={{  color: 'white', fontSize: '17px', textAlign: 'start', paddingLeft: '15px' }}>Its Online Service, we provide comprehensive car wash services to keep your vehicle looking its best.
               Our skilled team uses state-of-the-art equipment and high-quapty products to ensure your car receives top-notch treatment.Experienced and Professional Staff
                State-of-the-Art Equipment
                Environmentally Friendly Products
                Convenient Booking Options
                Flexible Service Packages</p>

            </div>
          </div>
        

        <div className="container  py-3 my-1 ">
          <div className="text-container text-light">
            <h4 style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', color: '#ff0000', textAlign: 'start', paddingLeft: '15px' }}>Contact us</h4>
            <p style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', color: 'white', fontSize: '17px', textAlign: 'start', paddingLeft: '15px' }}>Ready to give your car the care it deserves? Contact us today to schedule your car wash service. We offer flexible appointment options to accommodate your busy schedule.</p>
            <div style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', textAlign: 'start', paddingLeft: '15px' }}><IoMdContact style={{ fontSize: '200%', color: '#ff0000' }} /><h6>Contact Person</h6><h6 style={{ paddingLeft: '20px' }}>MR.Thirupathi Kodaganti,,</h6></div><br />
            <div style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', textAlign: 'start', paddingLeft: '15px' }}><FaSquarePhone style={{ fontSize: '200%', color: '#ff0000' }} /><h6>Phone Number</h6><h6 style={{ paddingLeft: '20px' }}>9704678952</h6></div><br />
            <div style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', textAlign: 'start', paddingLeft: '15px' }}><MdEmail style={{ fontSize: '200%', color: '#ff0000' }} /><h6>email Address</h6><h6 style={{ paddingLeft: '20px' }}>thirupathikodaganti7@gmail.com</h6></div><br />
            <div style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', textAlign: 'start', paddingLeft: '15px' }}><BiSolidTimeFive style={{ fontSize: '200%', color: '#ff0000' }} /><h6>Company Timmings</h6><h6 style={{ paddingLeft: '20px' }}>8.30 AM to 10.00 PM</h6></div><br />
            <div style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', textAlign: 'start', paddingLeft: '15px' }}><SlCalender style={{ fontSize: '200%', color: '#ff0000' }} /><h6 >Working Days</h6><h6 style={{ paddingLeft: '20px' }}>7 days a Week,9.00 AM to 10.00 PM</h6></div>


            <div className='' style={{ fontFamily: 'Roboto, Arial, Helvetica Neue', color: '#ff0000', textAlign: 'start', paddingLeft: '15px' }}>
              <button className='btn btn text-light  text-decoration-underline' onClick={() => navigate('/')}>Home</button><br />
              <button className='btn btn-outline text-light  text-decoration-underline' onClick={() => navigate('/logo')} >Booking</button><br />
              <button className='btn btn-outline text-light  text-decoration-underline' onClick={() => navigate('/About')} >About</button><br />

              <button className='btn btn-outline text-light  text-decoration-underline' onClick={() => navigate('/Policies')} >Policies</button><br />
            </div>
          </div>

        </div>

        </div></div>
        <div><Topbutton/></div>
    </>
  );
};

export default CarWashServices;






