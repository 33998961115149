import React from "react";
import Helo from '../Assates/IMG_20240327_113552.png.jpg';
import './about.css'
import { useNavigate } from 'react-router-dom';
//import img1 from '../Assates/car-wash-vector-free-29_clipped_rev_1-1.png'
//import map from '../Assates/Untitled7890.png'
import robo from '../Assates/slider-1.png'
import th from '../Assates/Simple-Minimalist-Background-Image.png'
//import two from'../Assates/curve-vector-1.png'

const About = () => {
  const navigate = useNavigate();

  return (
    <>

      <div>

        <div className="about-container" >

          <center><h3 style={{color:'red'}} >About Us</h3></center><br />

          <img src={Helo} alt="Hello" className="responsive-image  " style={{ borderRadius: '100%' }} /><strong  style={{color:'red'}}>Founder </strong></div>
        <center><div>


          <div style={{ textAlign: 'start', paddingLeft: '15px' }}>
            <h6>Wel-Come to MRobotic Car washing ! Iam Thirupathi Kodaganti </h6>
            <h6>With love for Business, He built his own Application without looking for opportunities to provide Good Service Today.</h6><br />
            <center><h3 style={{color:'red'}}>About MrRobotic</h3></center> <br />

       <img src={robo} alt="Hello" className="responsive-image "/><br/><br/>

            <h6>
          
              At MrRobotic Car Washing, we are dedicated to providing top-notch car cleaning services using the latest
              technology and environmentally friendly practices.
            </h6>
            <h6>
              Our mission is to make your car shine like new while minimizing our impact on the environment. We use
              state-of-the-art robotic technology to ensure precision and efficiency in every car wash.
            </h6>
            <h6>
              Whether you need a quick wash or a detailed cleaning, we are here to exceed your expectations. Our
              experienced team is committed to delivering excellence and ensuring your satisfaction with our services.
            </h6>
            <h6>Thank you for choosing Mr. Robotic Car Washing!</h6>
          </div></div></center>
          
          
          <div className='container d-flex flex-wrap my-0 py-0 text-light position-relative' style={{ backgroundImage: `url(${th})`, backgroundSize: 'cover', backgroundPosition: 'center', height:'83vh'}}>
  <div >
    <h6 className='my-0 ms-3 fs-3' style={{ color: 'red', padding: '10px', borderRadius: '5px' }}>Contact Info</h6>
    <div className='my-0 ms-3' style={{ textAlign: 'start', fontFamily: 'Roboto, Arial, Helvetica Neue', padding: '20px', borderRadius: '5px' }}>
      <h6>Company : <br />MRRobotic Car Washing 360</h6>
      <h6>Email : mrroboticcarwashing360@gmail.com</h6>
      <h6>Contact Number: <a href="tel:+919704678952">+91 9704678952</a></h6>
      <h6>Servicess Timings :<br />8:30am to 9:00pm</h6>
      <h6>Address :<br />Karimnager Road,Opposite Jagtial Power Station , Jagtial, Telangana , Pin:505327 </h6>
      <div>
        <button className='btn btn text-light text-decoration-underline mb-2' onClick={() => navigate('/')}>Home</button><br />
        <button className='btn btn-outline text-light text-decoration-underline mb-2' onClick={() => navigate('/logo')} >Booking</button><br />
        <button className='btn btn-outline text-light text-decoration-underline mb-2' onClick={() => navigate('/services')} >Services</button><br />
      </div>
    </div>
  </div>
  <div className="my-0 py-0" style={{ opacity: '0.5' }}>
  </div>
</div>




      </div>

    </>
  )
}

export default About



