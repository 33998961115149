import React,{useState,useEffect} from "react";
import Ian from '../Assates/maxresdefault (1).jpg';
import Iam from '../Assates/car_interior_cleaning_features1.jpg';
import Ias from '../Assates/beautiful-car-polishing-service_23-2149212244.png';
import Ips from '../Assates/WhatsApp Image 2024-04-15 at 22.24.53_b8f2c122.png'
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import Revies from '../Components8/Revies'

const Bookings = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }, []);
   
    return (
      <>
        <div style={{ backgroundColor: loading ? 'transparent' : '' }}>

          {loading ? (
          <center><div style={{top:'200px'}}  className='loader my-5 '></div></center>
          ) : (
        <>
                <div><button className='rounded-5 btn btn-light btn-sm  ' onClick={()=>navigate('/bookNow')}><IoMdArrowRoundBack className='fs-4 'style={{paddingBottom:'3px'}} />Back</button></div> <br/>

        <div className="container ">

        <strong  style={{  color:'red'}} className="text-decoration-underline  fs-5" > OFF-LINE SERVICE BOOKINGS</strong>
       </div>
               <div className="container-fluide"  >

                   <div className="booking-wrapper p-3 my-0  ">
                        <div  onClick={() => navigate("/booking-forms")} className="booking-item me- py-0 shadow">

                            <img src={Ian} alt="Under Body & Body" className="booking-image" />
                            <div className="booking-description ">Lower Body,Full Body </div>
                            <div className="booking-description text-danger"> Max 20 Minuts Time</div>

                            <h6 className="text-primary">Rs.249 </h6>
                            <center>   <button
                                type="submit"
                                className="booking-button2  rounded-5  my-4 me-3 py-2 " 
                                onClick={() => navigate("/booking-forms")}
                            >
                                Go To Book
                            </button></center> 
                        </div>
                        <div  onClick={() => navigate("/booking-forms-bikes")} className="booking-item ms-3 py-0 shadow">
                            <img src={Ips} alt="Inner Body & Ceramic" className="booking-image" />
                            <div className="booking-description">Lower Body & Full Body with Interior Washing  </div>
                            <div className="booking-description text-danger"> Max 30 Minuts</div>

                            <h6 className="text-primary">Rs.449</h6>
                           <center><button
                                type="submit"
                                className="booking-button2  rounded-5  my-4 me-3 py-2" 
                                onClick={() => navigate("/booking-forms-bikes")}
                            >
                                Go To Book
                            </button></center>
                        </div>
                   


                        <div  onClick={() => navigate("/booking-forms-cars")}  className="booking-item me-2 py-0 shadow">
                            <img src={Iam} alt="Inner Body & Ceramic" className="booking-image" />
                            <div className="booking-description "> Deep Cleaning intearior & Body Washing</div>
                            <div className="booking-description text-danger"> Max 4 Hours Time</div>

                            <h6 className="text-primary">Rs.999</h6>
                           <center><button
                                type="submit"
                                className="booking-button2  rounded-5  my-4 me-3 py-2" 
                                onClick={() => navigate("/booking-forms-cars")}
                            >
                                Go To Book
                            </button></center> 
                        </div>
                        <div  onClick={() => navigate("/booking-forms-ceramics")} className="booking-item my-2 py-0 shadow">
                            <img src={Ias} alt="Inner Body & Ceramic" className="booking-image" />
                            <div className="booking-description">Special Body Washing wax & Polishing </div>
                            <div className="booking-description text-danger"> 3 Hours Hours Time</div>

                            <h6 className="text-primary">Rs.1499</h6> 
                           <center><button
                                type="submit"
                                className="booking-button2 rounded-5  my-4 me-3 py-2" 
                                onClick={() => navigate("/booking-forms-ceramics")}
                            >
                                Go To Book
                            </button></center> 
                        </div>
                       

                    </div>
               
              
              </div>
              <div className="card-body ms-2 me-2 my-3 "  >
                <table>
                  <thead >
                    <tr style={{backgroundColor:'red'}} >
                      <th className='text-decoration-underline'>OFF-LINE SERVICESS TYPES</th>
                      <th className='text-decoration-underline'> PRICE </th>
                    </tr>
                  </thead >
                  <tbody >
                    <tr  >
                      <td >Car Full Body And Lower Body Washing</td>
                      <td style={{color:'red'}}>Rs.249</td>
                    </tr>
                    <tr>
                      <td>Car Lower Body & Full Body With Interior Washing with Steam</td>
                      <td style={{color:'red'}}>Rs.449</td>
                    </tr>
                    <tr >
                      <td>Deep Cleaning Interior & Full Body Washing with Steam</td>
                      <td  style={{color:'red'}}>Rs.999</td>
                    </tr>
                    <tr>
                      <td>Special Body Washing Wax & Polishing</td>
                      <td style={{color:'red'}}>Rs.1499</td>
                    </tr>
                    
                  </tbody>
                </table>
              </div> 
              <Revies/>
              </>
              )}
              </div>
            
        </>
    );
};

export default Bookings;






