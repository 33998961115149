
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import successSound from '../Assates/short-success-sound-glockenspiel-treasure-video-game-6346 (1).mp3';
import Fail from "../Assates/error-when-entering-the-game-menu-132111.mp3";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './bookings2.css'
import img4 from '../Assates/car-wash-vector-free-29_clipped_rev_1-1.png'
import { IoMdArrowRoundBack } from "react-icons/io";

const firebaseConfig = {
    apiKey: "AIzaSyCY3yCgIGipMyVtI0Q9wGzDBoq3mHtAO5I",
    authDomain: "car-360-washing.firebaseapp.com",
    databaseURL: "https://car-360-washing-default-rtdb.firebaseio.com",
    projectId: "car-360-washing",
    storageBucket: "car-360-washing.appspot.com",
    messagingSenderId: "950691338224",
    appId: "1:950691338224:web:ebfc5e9cf08eaa113aef35",
    measurementId: "G-G4R22J24V2"
};
firebase.initializeApp(firebaseConfig);

const BookingForm = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [servicess, setServicess] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [car, setCar] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const [loading, setLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const successAudio = new Audio(successSound);
    const fail = new Audio(Fail);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const user = firebase.auth().currentUser;
            if (!user) {
                // Handle case where user is not authenticated
                return;
            }

            if (!number.trim() || number.trim().length !== 10) {
                setPhoneNumberError('please check the number.');
                return;
            } else {
                setPhoneNumberError('');
            }
           
            if (!servicess) {
                swal("Oops!", "Please select a service.", "error");
                return;
              }
        

            console.log('Disabling button...');
            setButtonDisabled(true); // Disable the button to prevent multiple submissions



            const db = firebase.database();
            const bookingDate = new Date().toISOString();
            await db.ref('bookings').push({
                userId: user.uid,
                name,
                number,
                servicess,
                car,
                date: selectedDate,
                bookingDate,

            });


            const templateParams = {
                user_name: name,
                from_number: number,
                servicess_name: servicess,
                car_name: car,
                date_name: selectedDate,
            };

            const emailResult = await emailjs.send('service_w3ch35g', 'template_y01puhl', templateParams, 'qbc57SvIGgzNB0F5-');
            console.log(emailResult.text);
            successAudio.play();

            swal("Thank you !", "Your booking successfully!", "success").then(() => {
                navigate('/Booking-Details', { state: { bookingInfo: { servicess, car } } });
            })
            e.target.reset();
            setName('');
            setServicess('');
            setCar('')
            setSelectedDate('')

            console.log('Enabling button...');
            setButtonDisabled(false); // Re-enable the button after successful submission
            // Re-enable the button after successful submission
        } catch (error) {
            console.error("Error:", error);

            //console.error(error);
            fail.play();
            swal("Sorry!", "Your booking failed. Please try again later.", "error");
            console.log('Enabling button due to error...');
            setButtonDisabled(false);  // Re-enable the button in case of failure
        }
    }

    return (
        <>
            <div className="" style={{ backgroundColor: loading ? 'transparent' : '' }}>
                {loading ? (
                    <center><div style={{ top: '200px' }} className='loader my-5 '></div></center>
                ) : (
                    <>
                        <div><button className='rounded-5 btn btn-light btn-sm ' onClick={() => navigate('/bookings2')}><IoMdArrowRoundBack className='fs-4' style={{ paddingBottom: '3px' }} />Back</button></div><br />
                        <center><div className='fs-4  text-decoration-underline'>Offline Booking Service</div></center>

                        <div className="container bg-danger border border-1 text-black" >

                            <img src={img4} width={900} height={218} alt='wash' className="image " />
                            <div className="text-container text-black me-3 my-0"></div>

                            <header style={{ fontFamily: 'serif' }} >

                                <div className='py-0'>

                                    <form className='py-3' onSubmit={handleSubmit}>
                                        <label>
                                            Full Name:
                                            <input type="text" placeholder='Enter Full Name' value={name} onChange={(e) => setName(e.target.value)} required />
                                        </label>
                                        <label>
                                            Phone No:
                                            <input value={number} placeholder='Enter Ph Number' onChange={(e) => setNumber(e.target.value)} />
                                            {phoneNumberError && <p style={{ color: 'red' }}>{phoneNumberError}</p>}
                                        </label>
                                        <label>
                                            Select Date:
                                            <input
                                                type="date"
                                                value={selectedDate}
                                                onChange={(e) => setSelectedDate(e.target.value)}
                                                required
                                            />
                                        </label>
                                        <label>
                                            Vehicle Number:
                                            <input type="text" value={car} placeholder='Vehicle Number' onChange={(e) => setCar(e.target.value)} required />

                                            </label>
                                      
                                        <label className='text-black  '>
                                            <strong className='me-5 text-black'>Check Service:</strong>
                                            <div >

                                                <div className="service-option"  >
                                                    <input
                                                        type="checkbox"
                                                        name="service"
                                                        value=" Lower Body & Full Body with Interior Washing - PRICE Rs.449"
                                                        checked={servicess === " Lower Body & Full Body with Interior Washing - PRICE Rs.449"}
                                                        onChange={(e) => setServicess(e.target.value)}
                                                    />
                                                    <label className='fs-6'>
                                                        Lower Body & Full Body with Interior Washing - PRICE Rs.449</label>
                                                </div>
                                            </div>
                                        </label>


                                      
                                        <center> <div className='me-3 btn btn-sm'>
                                            <button
                                                type="submit"
                                                style={{ borderRadius: '10px', width: '300px', opacity: buttonDisabled ? 0.3 : 15, cursor: buttonDisabled ? 'not-allowed' : 'pointer' }}
                                                disabled={buttonDisabled} // Disable the button if it's already clicked
                                            >
                                                <div >Book Now</div>
                                            </button>
                                        </div></center>
                                    </form>
                                </div>
                            </header></div></>
                )}
            </div>
        </>

    );
};

export default BookingForm;




