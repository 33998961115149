import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCY3yCgIGipMyVtI0Q9wGzDBoq3mHtAO5I",
  authDomain: "car-360-washing.firebaseapp.com",
  databaseURL: "https://car-360-washing-default-rtdb.firebaseio.com",
  projectId: "car-360-washing",
  storageBucket: "car-360-washing.appspot.com",
  messagingSenderId: "950691338224",
  appId: "1:950691338224:web:ebfc5e9cf08eaa113aef35",
  measurementId: "G-G4R22J24V2"
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

