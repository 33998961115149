import React, { useState, useEffect } from "react";
import Ips from '../Assates/maxresdefault.jpg';
import Iam from '../Assates/th (1).png';
import Ias from '../Assates/maxresdefault (4).png'
import { useNavigate } from 'react-router-dom';
import '../Components1/Bookings.css'
import { IoMdArrowRoundBack } from "react-icons/io";
import Revies from '../Components8/Revies';

const Bookings = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            <div style={{ backgroundColor: loading ? 'transparent' : '' }}>

                {loading ? (
                    <center><div style={{ top: '200px' }} className='loader my-5 '></div></center>
                ) : (
                    <>
                        <div><button className='rounded-5 btn btn-light btn-sm ' onClick={() => navigate('/bookNow2')}><IoMdArrowRoundBack className='fs-4' style={{ paddingBottom: '3px' }} />Back</button></div><br />

                        <div className="container">
                            <strong style={{ fontFamily: 'sans-serif', fontSize: '30px', color: 'green' }} className="text-decoration-underline fs-5">ON-LINE BOOKING SERVICESS</strong>
                        </div>
                        <div className="container-fluide">

                            <div className="booking-wrapper p-3 my-0 ">

                                <div onClick={() => navigate("/booking-form10")} className="booking-item  me-1 py-0 shadow">

                                    <img src={Ips} alt="Inner Body & Ceramic" className="booking-image" />
                                    <div className="booking-description ">Two wheeler Special Deep water Washing </div>
                                    <div className="booking-description text-danger">Quick Delivery</div>

                                    <h6 className="text-primary">Rs.99</h6>

                                    <center><button
                                        type="submit"
                                        className="booking-button1 rounded-5  my-4 me-2 py-2 "
                                        onClick={() => navigate("/booking-form10")}
                                    >
                                        Go To Book
                                    </button></center>
                                </div>


                               
                                <div onClick={() => navigate("/booking-form11")} className="booking-item  me-2 py-0 shadow">

                                    <img src={Iam} alt="Inner Body & Ceramic" className="booking-image" />
                                    <div className="booking-description "> Deep Cleaning Special Foam Washing & Steam</div>
                                    <div className="booking-description text-danger"> 1 HourTime </div>

                                    <h6 className="text-primary">Rs.199</h6>

                                    <center><button
                                        type="submit"
                                        className="booking-button1 rounded-5  my-4 me-2 py-2"
                                        onClick={() => navigate("/booking-form11")}
                                    >
                                        Go To Book
                                    </button></center>
                                </div>
                                <div onClick={() => navigate("/booking-form12")} className="booking-item me-1 py-0  shadow">

                                    <img src={Ias} alt="Inner Body & Ceramic" className="booking-image" />
                                    <div className="booking-description">Special Body Washing With Wax & Polishing</div>
                                    <div className="booking-description text-danger">2 Hours Time</div>

                                    <h6 className="text-primary">Rs.449</h6>

                                    <center><button
                                        type="submit"
                                        className="booking-button1   rounded-5  my-4 me-2 py-2"
                                        onClick={() => navigate("/booking-form12")}
                                    >
                                        Go To Book
                                    </button></center>
                                </div>



                            </div>
                        </div>

                        <div className="card-body my-4 ms-2 me-2 "  >
                            <table  >
                                <thead>
                                    <tr style={{ backgroundColor: '#08a40b' }} >
                                        <th className='text-decoration-underline fs-0'> ON-LINE PICKUP & DELIVERY</th>
                                        <th className='text-decoration-underline' >PRICE </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr  >
                                        <td >Two wheeler Foam Water Washing</td>
                                        <td style={{ color: 'red' }}>Rs.99</td>
                                    </tr>
                                    <tr>
                                        <td>Deep Cleaning Special Foam Washing & Steam</td>
                                        <td style={{ color: 'red' }}>Rs.199</td>
                                    </tr>
                                    <tr >
                                        <td>Special Body Washing With Wax & Polishing</td>
                                        <td style={{ color: 'red' }}>Rs.449</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Revies />
                    </>

                )}</div>

        </>
    );
};
export default Bookings;






