import React, { useState, useEffect } from 'react';
import './topbutton.css'; // You can create a CSS file for styling

const TopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll event and toggle button visibility
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 300) { // Change this value according to your needs
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Effect to add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle scrolling to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      {isVisible && (
        <button className="scroll-to-top" style={{backgroundColor:'red'}} onClick={scrollToTop}>
         ^        
        </button>
      )}
    </div>
  );
};

export default TopButton;
