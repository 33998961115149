// WhatsAppButton.jsx

import React from 'react';
import {  useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import whatsapp from '../Assates/whatsapp.png'

const WhatsAppButton = () => {
  const history = useNavigate();

  const handleWhatsAppClick = () => {

    // Replace '123456789' with the actual phone number you want to message
    const phoneNumber = '9704678952';

    // Replace 'Hello!' with the desired pre-filled message
    const message = encodeURIComponent('Hello!');

    // Create the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open the link in a new tab or window
    window.open(whatsappLink, '_blank');
    history('/')
  };

  return (
   <>
       <div><button className='rounded-5 btn btn-light btn-sm ' onClick={()=>history('/')}><IoMdArrowRoundBack className='fs-4' style={{paddingBottom:'4px'}} />Back</button></div> 

   <center><div className='container'><br/><br/>
    <h5>"Click below Icon" Chart with me</h5></div>
    <img src={whatsapp} width={55}  style={{color:'#00ff00',cursor: 'pointer'}} alt='sender'onClick={handleWhatsAppClick}/>
  
  
   
    </center></> 
  );
};

export default WhatsAppButton;
