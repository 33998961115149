
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ResponsiveDrawer from './Components2/ResponsiveDrawer';
import Home from './Components1/Home';
import Bookings from './Components1/Bookings';
import BookingForms from './Components1/BookingFormList2';
import BookingForms3 from './Components1/BookingFormList3';
import BookingForms4 from './Components1/BookingFormList4';
import BookingForms5 from './Components1/BookingFormList5';
import CarwashServices from './Components1/CarWashServices';
import About from './Components3/About';
import Iam from './Assates/Picsart_24-03-27_11-15-17-563.png';
import Policies from './Components1/Policies';
import './App.css';
import WhatsApp from './Components5/Whatsapp';
import Whatsapp from './Components5/Whatsapp2';
import Booknow from './Components7/Booknow';
import Bookings2 from './Coponents6/Bookings2';
import BookingForms6 from './Coponents6/BookingFormList6';
import BookingForms7 from './Coponents6/BookingFormList7';
import BookingForms8 from './Coponents6/BookingFormList8';
import BookingForms9 from './Coponents6/BookingFormList9';
import { auth, googleProvider } from './Components4/firebase';
import { FcGoogle } from "react-icons/fc";
import Video from './Components13/BookingDetails';
import Logo from './Components10/Logo';
import Ceramic from './Components11/Ceramic'
import Booknow2 from './Components12/Bookingnow2'
import BookingDetails from './Components13/BookingDetails';
import Bookings3 from './Components14/Bookings3';
import Bookings4 from './Components14/Bookings4';
import BookingForms10 from './Components14/BookingFormList10';
import BookingForms11 from './Components14/BookingFormList11';
import BookingForms12 from './Components14/BookingFormList12';
import BookingForms13 from './Components14/BookingFormList13';
import BookingForms14 from './Components14/BookingFormList14';
import BookingForms15 from './Components14/BookingFormList15';

const App = () => {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });


    return () => {

      clearTimeout(timer);
      unsubscribe();

    }
  }, []);



  const handleSignInWithGoogle = async () => {
    try {
      await auth.signInWithRedirect(googleProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };


  return (
    <>
      {loading ? (

        <center>       
          

       <div style={{ height: '100vh', paddingTop: '150px', backgroundColor: '#f7df63' }}>

          <img src={Iam} width={300} alt="Loading..." style={{ borderRadius: '100%' }} ></img>
          <h6 className=' fs-5'>Hello-JAGTIAL</h6><br />

        </div>

        </center>


      ) : user ? (
        <div>



          <ResponsiveDrawer />
          <Routes>

            <Route path='/' element={<Home />} />
            <Route path='/services' element={<CarwashServices />} />
            <Route path='/bookNow' element={<Booknow />} />
            <Route path='/bookNow2' element={<Booknow2 />} />
            <Route path='/bookings' element={<Bookings />} />
            <Route path="/booking-form" element={<BookingForms />} />
            <Route path="/booking-form-car" element={<BookingForms3 />} />
            <Route path="/booking-form-ceramic" element={<BookingForms4 />} />
            <Route path="/booking-form-bike" element={<BookingForms5 />} />
            <Route path='/bookings2' element={<Bookings2 />} />
            <Route path='/booking-forms' element={<BookingForms6 />} />
            <Route path='/booking-forms-cars' element={<BookingForms7 />} />
            <Route path='/booking-forms-ceramics' element={<BookingForms8 />} />
            <Route path='/booking-forms-bikes' element={<BookingForms9 />} />
            <Route path='/About' element={<About />} />
            <Route path='/Policies' element={<Policies />} />
            <Route path='/apps' element={<WhatsApp />} />
            <Route path='/Appss' element={<Whatsapp />} />
            <Route path='/vedios' element={<Video />} />
            <Route path='/logo' element={<Logo />} />
            <Route path='/ceramic' element={<Ceramic />} />
            <Route path='/Booking-Details' element={<BookingDetails />} />
            <Route path='/bookings3' element={<Bookings3 />} />
            <Route path='/bookings4' element={<Bookings4 />} />
            <Route path="/booking-form10" element={<BookingForms10 />} />
            <Route path="/booking-form11" element={<BookingForms11 />} />
            <Route path="/booking-form12" element={<BookingForms12 />} />
            <Route path="/booking-form13" element={<BookingForms13 />} />
            <Route path="/booking-form14" element={<BookingForms14 />} />
            <Route path="/booking-form15" element={<BookingForms15 />} />

          </Routes>

        </div>
      ) : ( // Render Google Sign-In button if user is not signed in

        <div style={{ alignItems: 'center', paddingTop: '10%' }} >
          <center><div style={{ width: '250px', backgroundColor: 'white' }} className='  text-dark shadow p-3 py-5'><h6>Well-Come </h6><h6>To</h6><h6 className='card-title'>MR ROBOTIC CAR WASHING 360</h6><br /><br /><h5>Sign In</h5><br /><h5><FcGoogle className='fs-1' /> </h5>
            <button className='card-text rounded-5   me-3 my-1' onClick={handleSignInWithGoogle}>Sign In with Google</button>

          </div>
          </center></div>

      )}

    </>

  );
};

export default App;
